.about {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to right, #6d3353, #020024);
    padding: 1rem;
}

.profileImageContainer {
    flex: 0.3;
    position: relative;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileImage {
    width: 80%;
    border: 5px solid #fff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
    transition: all 0.5s ease;
    background: linear-gradient(to right, #6d3353, #020024);
}

.profileImage:hover {
    transform: scale(1.1);
    border-color: #6d3353;
}

.separator {
    content: "";
    position: absolute;
    right: -15px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #ccc;
}

.textContainer {
    flex: 0.7;
    padding-left: 15px;
    color: #f0f0f0;
    font-family: Arial, sans-serif;
    line-height: 1.5;
    text-align: left;
}

.heading {
    font-size: 2em;
    margin-bottom: 0.5em;
    border-right: .15em solid white; /* The typewriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    overflow: hidden; /* Ensures the content is only shown within the .heading element */
    animation: typing 4s steps(40, end), blink-caret .75s step-end 4s, fade-out 0.5s 4s forwards;
    word-wrap: break-word;
}

/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: white; }
}

/* The fade out effect */
@keyframes fade-out {
    to { border-color: transparent; }
}

.paragraph {
    margin-bottom: 1em;
}

/* Mobile styles */
@media (max-width: 600px) {
    .about {
        flex-direction: column;
    }

    .profileImageContainer, .textContainer {
        flex: 1;
        margin: 0;
    }

    .separator {
        display: none;
    }

    .profileImage {
        width: 60%;
    }

    .heading {
        font-size: 1.5em;
        white-space: normal;
    }
}