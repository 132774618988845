.footer {
    padding: 10px;
    left: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to right, #6d3353, #020024);
    text-align: center;
    color: #fff;
    font-family: Arial, sans-serif;
}

.contactButton {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    background-color: #6d3353;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 10px;
    margin-right: 10px; /* Add this line */
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.contactButton a {
    color: #fff;
    text-decoration: none;
}

.scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #6d3353;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: none; /* Initially hidden */
}

