.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: linear-gradient(to right, #6d3353, #020024);
    color: #fff;
    position: relative;
}

.nav-links {
    display: flex;
    gap: 1rem;
}

.left-text {
    margin-right: auto;
    font-size: 1.5em;
}

.link {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}   

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.gradient-text {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 5s ease infinite;
}

@media (max-width: 600px) {
    .nav-links {
        position: absolute;
        top: 100%;
        left: 0;
        flex-direction: column;
        background: linear-gradient(to right, #6d3353, #020024);
        width: 100%;
        display: none;
    }

    .link {
        width: 100%;
        text-align: center;
        padding: 1rem;
        border-top: 1px solid #fff;
    }

    .hamburger {
        display: block;
    }
}