#projects {
    background: linear-gradient(to right, #6d3353, #020024);
    padding: 1rem;
}

#projects h2 {
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
}

#projects .project-container {
    color: #fff;
    font-family: Arial, sans-serif;
    padding-top: 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

#projects .project {
    color: #fff;
    margin: 10px 0.5%;
    width: calc(33% - 1%);
    min-width: 200px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    position: relative;
    transition: width 1s ease;
    text-align: left;
}

#projects .project.selected {
    width: 100%;
    text-align: center;
}

#projects .project .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

#projects .project a.github-link {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    background-color: #6d3353;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 10px;
}

.project {
    transition: transform 0.3s ease box-shadow 0.3s ease;
}

.project:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.code-block {
    background-color: black;
    padding: 10px;
    overflow: auto;
    max-height: 500px; /* Adjust as needed */
    white-space: pre;
    text-align: left; /* Add this line */
    text-shadow: none;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.project-gradient {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 5s ease infinite;
}

/* Mobile styles */
@media (max-width: 600px) {
    #projects .project-container {
        flex-direction: column;
        align-items: center;
    }

    #projects .project {
        width: 90%;
        margin: 10px auto;
    }

    #projects .project.selected {
        width: 100%;
    }
}