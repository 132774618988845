#education {
    background: linear-gradient(to right, #6d3353, #020024);
    padding: 1rem;
}

#education h2 {
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
}

.education-container {
    color: #fff;
    font-family: Arial, sans-serif;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.education-card {
    margin: 10px 0.5%;
    min-width: 200px;
    height: auto;
    max-height: 300px;
    overflow: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: transform 0.3s ease box-shadow 0.3s ease;
    background: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    position: relative;
    transition: width 1s ease;
}

.education-card:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.education-card.selected {
    width: 100%;
    text-align: center;
}

.education-card:not(.selected) {
    width: calc(33% - 1%);
    text-align: left;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.education-list {
    list-style-type: disc;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.gradient-education {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 5s ease infinite;
}


/* Mobile styles */
@media (max-width: 600px) {
    .education-container {
        flex-direction: column;
        align-items: center;
    }

    .education-card, .education-card:not(.selected) {
        width: 90%;
        margin: 10px auto;
    }

    .education-card.selected {
        width: 100%;
    }
}